<template>
	<div id="cpm_liveType">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search v-model:value="key" placeholder="分类名称" style="width: 400px;" enter-button @search="getTypeList(1,info.limit)" />
				<a-button type="primary" v-has="{action:'wxapp_live_type_add',plat:isShop}" @click="addType(0)">
					<i class="ri-add-line"></i>新增分类
				</a-button>
				<a-button type="danger" v-has="{action:'wxapp_live_type_del',plat:isShop}" @click="deleteLiveType(pks)">
					<i class="ri-delete-bin-line"></i>批量删除
				</a-button>
			</a-space>
		</div>

		
		<div class="el-content">
			<a-table :data-source="info.list" :pagination="false" row-key="id" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'分类名称',dataIndex:'name'},
				{title:'排序',dataIndex:'rank'},
				{title:'是否显示',dataIndex:'is_show',slots:{customRender:'is_show'}},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
				<template #is_show="{record}">
					<a-tag :color="record.is_show == 1 ?'#87d068':'#999'">
						{{record.is_show == 1 ?'显示':'隐藏'}}
					</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_live_type_add'}"  @click="addType(record)"></kd-button>
						<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_live_type_del'}"  @click="deleteLiveType(record.id)"></kd-button>
					</a-space>
				</template>
			</a-table>
			
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getTypeList(info.page,e)}"
					@change="(e)=>{getTypeList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="isShow" title="认养分类" @ok="submitSave" width="600px" @cancel="isShow = false">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="分类名称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="排序" >
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import adoptModel from '@/api/adopt.js'
import common from '@/api/common.js'
export default{
	name:'page-adopt-type',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
		const _d = reactive({
			search:{key:""},
			pks:[],
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			isShow:false,
			form:null,
		})
		getTypeList(1,_d.info.limit)
		function getTypeList(page,limit){
			adoptModel.getAdoptCategroy(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		function addType(row){
			_d.form = {
				id: row ? row.id :0,
				name:row ? row.name:"",
				rank:row ? row.rank:99,
				status: row ? row.status :0,
			}
			_d.isShow = true
		}
		const deleteLiveType = (id)=>common.deleteDataList(id,50,"确认删除该认养分类吗").then(()=>{
			getTypeList(_d.info.page,_d.info.limit)
		})
		const submitSave = ()=>adoptModel.addOrEditCategory(_d.form,()=>{
			getTypeList(_d.info.page,_d.info.limit)
			_d.isShow = false
		})
		return {
			...toRefs(_d),
			getTypeList,
			deleteLiveType,
			addType,
			submitSave
		}
	}
}
</script>
<style lang="scss">
</style>
